<template>
  <b-form class="p-2">
    <b-row>
      <b-col
        class="d-flex pb-2"
        cols="12"
      >
        <h3 class="text-uppercase font-weight-bold">
          datos de pago
        </h3>
      </b-col>
      <b-col
        sm="12"
        md="6"
      >
        <b-row>
          <b-col sm="12">
            <b-form-group
              label="Forma de pago"
              label-for="purchase-invoice-type-payment"
            >
              <BaseSelect
                id="purchase-invoice-type-payment"
                :resource="$data.$constants.RESOURCES.RESOURCE_TYPE_PAYMENTS"
                :value="model.type_payment"
                @input="update('type_payment', $event)"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        sm="12"
        md="6"
      >
        <b-row>
          <b-col sm="12">
            <b-form-checkbox
              v-model="model.send_payment_document"
              :value="1"
              :unchecked-value="0"
              class="mt-2"
              @input="update('send_payment_document', $event)"
            >
              Envío de documento de pago
            </b-form-checkbox>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import FormMixin from '@/mixins/formMixin'
import BaseSelect from '@/components/ui/select/BaseSelect.vue'

export default {
  name: 'PurchaseInvoiceBillingForm',
  components: { BaseSelect },
  mixins: [FormMixin],
}
</script>

<style scoped>

</style>
